import { EditOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import {
  notification,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  PageHeader,
  Row,
  Space,
  Table,
  Switch,
  Modal as ModalAntd,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  actualizarEstadoAsistencia,
  obtenerEmpleadosAsistencia,
} from "../../api/empleados";
import EditAsistenciaModal from "../../components/ListaAsistencia/EditAsistenciaModal";
import Modal from "../../components/Modal";
import "./ListaAsistencia.scss";

const { confirm } = ModalAntd;

export default function ListaAsistenciaModal() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);
  const [baseData, setBaseData] = useState([]);
  const [showTemperature, setShowTemperature] = useState(false);
  const [asistenciasEnable, setAsistenciasEnable] = useState(true);
  const [fechaInicio, setFechaInicio] = useState(
    moment().startOf("month").format("DD-MM-YYYY")
  );
  const [fechaFinal, setFechaFinal] = useState(
    moment().endOf("month").format("DD-MM-YYYY")
  );
  const [filterTable, setFilterTable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function listarAsistencias() {
      let response = await obtenerEmpleadosAsistencia({
        nombreSede: "",
        fechaInicio: fechaInicio,
        fechaFinal: fechaFinal,
      });

      if (response) {
        let newArr = [];
        let numeroEmpleado = 1;
        response.data.forEach(function (item) {
          if (item.asistencia.length !== 0) {
            item.asistencia.forEach(function (itemAsis) {
              let i = itemAsis.detalleAsistencias.length - 1;
              let numAsistencia =
                itemAsis.detalleAsistencias.filter((v) =>
                  asistenciasEnable ? !v.disabled : v.disabled
                ).length - 1;
              itemAsis.detalleAsistencias.reverse().forEach((itemDetalle) => {
                let obj = {
                  _id: item._id,
                  nombre: `${item.apellidoPaterno} ${item.apellidoMaterno} ${item.nombre}`,
                  dni: item.dni,
                  fechaAsistencia: itemAsis.fechaAsistencia,
                  horaEntrada: itemDetalle.horaEntrada,
                  horaSalida: itemDetalle.horaSalida,
                  temperaturaEntrada: itemDetalle.temperaturaEntrada,
                  temperaturaSalida: itemDetalle.temperaturaSalida,
                  sede: itemDetalle.sede,
                  i: i,
                  numeroEmpleado: numeroEmpleado,
                  disabled: itemDetalle.disabled,
                  numAsistencia: numAsistencia,
                };
                newArr.push(obj);
                !itemDetalle.disabled && numAsistencia--;
                i--;
              });
            });
            numeroEmpleado++;
          }
        });

        setBaseData(
          newArr.filter((v) => (asistenciasEnable ? !v.disabled : v.disabled))
        );
        setIsLoading(false);
      }
    }
    listarAsistencias();
    setReload(false);
  }, [reload, asistenciasEnable]);

  const editAsistencia = (key, record) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar asistencia del ${record.fechaAsistencia}`);
    setModalContent(
      <EditAsistenciaModal
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        idEmpleado={key}
        datos={record}
        showTemperature={showTemperature}
      />
    );
  };

  const removerAsistencia = (id, record) => {
    confirm({
      title: `${!record.disabled ? "Eliminar" : "Recuperar"} asistencia de ${
        record.nombre
      } - ${record.fechaAsistencia}`,
      content: `
      Hora de Entrada: ${record.horaEntrada || "- - -"} |
      Hora de Salida: ${record.horaSalida || "- - -"}`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        let response = await actualizarEstadoAsistencia(id, record);
        if (response.code === 200) {
          notification["success"]({
            message: "Éxito",
            description: response.message,
          });
          setIsVisibleModal(false);
        } else if (response.code === 400) {
          notification["error"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["warning"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        }
        setReload(true);
      },
    });
  };

  const columns = showTemperature
    ? getCols(editAsistencia, removerAsistencia, asistenciasEnable)
    : getCols(editAsistencia, removerAsistencia, asistenciasEnable).filter(
        (item) =>
          item.key !== "temperaturaEntrada" && item.key !== "temperaturaSalida"
      );

  const search = (value) => {
    if (value !== "") {
      const filterTable = baseData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilterTable(filterTable);
    } else {
      setFilterTable(null);
    }
  };

  function onChange(date, dateString) {
    const fechaInicio = moment(date).startOf("month").format("DD-MM-YYYY");
    const fechaFinal = moment(date).endOf("month").format("DD-MM-YYYY");
    setFechaInicio(fechaInicio);
    setFechaFinal(fechaFinal);
    setIsLoading(true);
    setReload(true);
  }

  return (
    <>
      <PageHeader className="site-page-header" title="Lista de asistencias" />
      <Row>
        <Col lg={1} />
        <Col lg={22}>
          <div className="list-users-header">
            <Space>
              <Switch
                defaultChecked
                onChange={() => {
                  setAsistenciasEnable(!asistenciasEnable);
                  setFilterTable(null);
                  setIsLoading(true);
                }}
              />
              <span>
                {asistenciasEnable
                  ? "Asistencias habilitada"
                  : "Asistencias removidas"}
              </span>
            </Space>
          </div>
          <Row>
            <Col lg={3}>
              <DatePicker
                onChange={onChange}
                picker="month"
                defaultValue={moment()}
                format="YYYY-MM"
              />
            </Col>

            <Col lg={4}>
              <Checkbox
                style={{ paddingTop: "5px", marginLeft: "4px" }}
                onChange={(e) => setShowTemperature(e.target.checked)}
              >
                Agregar temperaturas
              </Checkbox>
            </Col>
            <Col lg={16}>
              <Input.Search
                style={{ margin: "0 0 10px 0" }}
                placeholder="Buscar..."
                enterButton
                onSearch={search}
              />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={filterTable == null ? baseData : filterTable}
            rowKey={(record) =>
              `${record._id}${record.fechaAsistencia}${record.i}`
            }
            loading={isLoading}
          />
        </Col>
        <Col lg={1} />
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        footer={false}
      >
        {modalContent}
      </Modal>
    </>
  );
}

const getCols = (editAsistencia, removerAsistencia, asistenciasEnable) => [
  {
    title: "N°",
    dataIndex: "numeroEmpleado",
    key: "numeroEmpleado",
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
    sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    sortDirections: ["descend", "ascend"],
    render: (text) => text,
  },
  {
    title: "DNI",
    dataIndex: "dni",
    key: "dni",
  },
  {
    title: "Fecha asistencia",
    dataIndex: "fechaAsistencia",
    key: "fechaAsistencia",
  },
  asistenciasEnable
    ? {
        title: "N° entrada",
        dataIndex: "numAsistencia",
        key: "numAsistencia",
        render: (text) => `${Number(text) + 1}er`,
      }
    : {},
  {
    title: "Sede",
    dataIndex: "sede",
    key: "sede",
  },
  {
    title: "H. de entrada",
    dataIndex: "horaEntrada",
    key: "horaEntrada",
  },
  {
    title: "T. de entrada",
    dataIndex: "temperaturaEntrada",
    key: "temperaturaEntrada",
    render: (text) => (text ? `${text} °C` : "---"),
  },
  {
    title: "H. de salida",
    dataIndex: "horaSalida",
    key: "horaSalida",
    render: (text) => (text.length ? text : "-----"),
  },
  {
    title: "T. de salida",
    dataIndex: "temperaturaSalida",
    key: "temperaturaSalida",
    render: (text) => (text ? `${text} °C` : "---"),
  },
  {
    title: "Acciones",
    key: "action",
    render: (text, record) =>
      asistenciasEnable ? (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editAsistencia(record._id, record)}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={async () => removerAsistencia(record._id, record)}
          />
        </Space>
      ) : (
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={async () => removerAsistencia(record._id, record)}
        />
      ),
  },
];
