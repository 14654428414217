import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from "file-saver";

const columnas = [
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",
  "BA",
  "BB",
  "BC",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BK",
  "BL",
  "BM",
  "BN",
  "BO",
  "BP",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BW",
  "BX",
  "BY",
  "BZ",
  "CA",
  "CB",
  "CC",
  "CD",
  "CE",
  "CF",
  "CG",
  "CH",
  "CI",
  "CJ",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CP",
  "CQ",
  "CR",
  "CS",
  "CT",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DA",
  "DB",
  "DC",
  "DD",
  "DE",
  "DF",
  "DG",
  "DH",
  "DI",
  "DJ",
  "DK",
  "DL",
  "DM",
  "DN",
  "DO",
  "DP",
  "DQ",
  "DR",
  "DS",
  "DT",
  "DU",
  "DV",
  "DW",
  "DX",
  "DY",
  "DZ",
];
const dia = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const meses = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SETIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];
const verde = "00B655";
const amarillo = "FFFB01";
const azul = "B1C9FF";
const azulado = "5ECFC6";
const rojo = "EA8585";
const morado = "AEA6F3";
const verdeClaro = "98F399";
const verdeLimon = "AFBB4B";
const gris = "DEDEDE";
const blanco = "FFFFFF";

export const crearExcel = async (props) => {
  const { mes, anio, fechaInicio, fechaFinal, nombreSede, listaEmpleados } =
    props;
  const diaFinal = fechaFinal.split("-")[0];

  const wb = new ExcelJS.Workbook();

  let titulo = `REPORTE GENERAL ${meses[parseInt(mes) - 1]}-${anio}`;
  let nombreArchivo = `ASISTENCIAS GENERAL ${mes}-${anio}.xlsx`;
  if (nombreSede != 0) {
    titulo = `REPORTE '${nombreSede.toUpperCase()}' ${
      meses[parseInt(mes) - 1]
    }-${anio}`;
    nombreArchivo = `ASISTENCIAS ${nombreSede.toUpperCase()} ${mes}-${anio}.xlsx`;
  }

  let wsPrincipal = wb.addWorksheet(titulo);
  await layoutExcelPrincipal(
    wsPrincipal,
    diaFinal,
    mes,
    anio,
    fechaInicio,
    fechaFinal,
    nombreSede
  );
  await wsGeneral(wsPrincipal, listaEmpleados, diaFinal, mes, anio);

  await detalleFecha(wb, diaFinal, mes, anio, listaEmpleados, nombreSede);

  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreArchivo);
};

const wsGeneral = async (ws, listaEmpleados, diaFinal, mes, anio) => {
  let x = 1;
  listaEmpleados.map((empleado) => {
    const fila = x + 5;
    let row = [
      { richText: [{ text: x }] },
      { richText: [{ text: empleado.dni }] },
      {
        richText: [
          {
            text: `${empleado.apellidoPaterno} ${empleado.apellidoMaterno} ${empleado.nombre}`,
          },
        ],
      },
      { richText: [{ text: empleado.especialidad }] },
    ];
    const asistencia = empleado.asistencia;
    let estilosCeldas = [];
    let k = asistencia.length - 1;
    for (let i = 0; i < diaFinal; i++) {
      let fechaRecorrido = `${dia[i]}-${mes}-${anio}`;
      if (k >= 0) {
        if (asistencia[k].fechaAsistencia == fechaRecorrido) {
          const obs = observaciones(asistencia[k].detalleAsistencias);
          row.push({
            richText: [
              { text: asistencia[k].detalleAsistencias[0].horaEntrada },
            ],
          });
          row.push({
            richText: [
              {
                text: asistencia[k].detalleAsistencias[obs.posUltimaFechaSalida]
                  .horaSalida,
              },
            ],
          });
          row.push({ richText: [{ text: obs.obsDescripcion }] });
          estilosCeldas.push(azulado);
          estilosCeldas.push(azulado);
          if (obs.obsDescripcion == "- FIEBRE -") {
            estilosCeldas.push(rojo);
          } else if (obs.obsDescripcion == "- Salida -") {
            estilosCeldas.push(morado);
          } else {
            estilosCeldas.push(azulado);
          }
          k--;
        } else {
          row.push({ richText: [{ text: "- - -" }] });
          row.push({ richText: [{ text: "- - -" }] });
          row.push({ richText: [{ text: "- - -" }] });
          estilosCeldas.push(azulado);
          estilosCeldas.push(azulado);
          estilosCeldas.push(azulado);
        }
      } else {
        row.push({ richText: [{ text: "- - -" }] });
        row.push({ richText: [{ text: "- - -" }] });
        row.push({ richText: [{ text: "- - -" }] });
        estilosCeldas.push(azulado);
        estilosCeldas.push(azulado);
        estilosCeldas.push(azulado);
      }
    }

    ws.addRow(row);
    estilarCelda(ws, `A${fila}`, azul);
    estilarCelda(ws, `B${fila}`, azul);
    estilarCelda(ws, `C${fila}`, azul);
    estilarCelda(ws, `D${fila}`, azul);
    for (let i = 0; i < diaFinal * 3; i++) {
      const celda = `${columnas[i]}${fila}`;
      estilarCelda(ws, celda, estilosCeldas[i], true);
    }
    x++;
  });
};

const layoutExcelPrincipal = async (
  ws,
  diaFinal,
  mes,
  anio,
  fechaInicio,
  fechaFinal,
  nombreSede
) => {
  ws.views = [{ state: "frozen", xSplit: 4, ySplit: 5 }];
  if (nombreSede.length != 0) {
    ws.getCell("A1").value = {
      richText: [
        {
          font: { bold: true, size: "16" },
          text: `SEDE: ${nombreSede.toUpperCase()}`,
        },
      ],
    };
  } else {
    ws.getCell("A1").value = {
      richText: [{ font: { bold: true, size: "16" }, text: "REPORTE GENERAL" }],
    };
  }
  ws.getCell("E1").value = {
    richText: [
      { font: { bold: true, size: "25" }, text: "FUJITA GUMI S.A.C." },
    ],
  };
  ws.getCell("E2").value = {
    richText: [
      {
        font: { bold: true, size: "20" },
        text: `Reporte de asistencias: ${meses[parseInt(mes) - 1]} - ${anio}`,
      },
    ],
  };
  ws.getCell("E3").value = {
    richText: [
      {
        font: { bold: true, size: "16" },
        text: `${fechaInicio} | ${fechaFinal}`,
      },
    ],
  };

  ws.mergeCells("A1:D3");
  ws.mergeCells("E1:Z1");
  ws.mergeCells("E2:Z2");
  ws.mergeCells("E3:Z3");
  ws.mergeCells(`AA1:${columnas[diaFinal * 3 - 1]}1`);
  ws.mergeCells(`AA2:${columnas[diaFinal * 3 - 1]}2`);
  ws.mergeCells(`AA3:${columnas[diaFinal * 3 - 1]}3`);
  ws.addRow([
    { richText: [{ font: { bold: true }, text: "N° " }] },
    { richText: [{ font: { bold: true }, text: "DNI" }] },
    { richText: [{ font: { bold: true }, text: "Nombres" }] },
    { richText: [{ font: { bold: true }, text: "Cargo/Esp." }] },
  ]);
  ws.getCell("A4").border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  ws.getCell("B4").border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  ws.getCell("C4").border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  ws.getCell("D4").border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  ws.mergeCells("A4:A5");
  ws.mergeCells("B4:B5");
  ws.mergeCells("C4:C5");
  ws.mergeCells("D4:D5");
  ws.getCell("A4").alignment = { vertical: "top", horizontal: "center" };
  ws.getCell("B4").alignment = { vertical: "top", horizontal: "center" };
  ws.getCell("C4").alignment = { vertical: "top", horizontal: "center" };
  ws.getCell("D4").alignment = { vertical: "top", horizontal: "center" };

  let x = 0;
  for (let i = 0; i < diaFinal; i++) {
    let col = `${columnas[x]}4`;
    ws.getCell(col).value = {
      richText: [{ font: { bold: true }, text: `${dia[i]}/${mes}/${anio}` }],
    };
    ws.getCell(col).alignment = { horizontal: "center" };
    estilarCelda(ws, col, amarillo);
    ws.getCell(`${columnas[x]}5`).value = {
      richText: [{ font: { bold: true }, text: "Entrada" }],
    };
    ws.getCell(`${columnas[x + 1]}5`).value = {
      richText: [{ font: { bold: true }, text: "Salida" }],
    };
    ws.getCell(`${columnas[x + 2]}5`).value = {
      richText: [{ font: { bold: true }, text: "Obs." }],
    };
    estilarCelda(ws, `${columnas[x]}5`, amarillo);
    estilarCelda(ws, `${columnas[x + 1]}5`, amarillo);
    estilarCelda(ws, `${columnas[x + 2]}5`, amarillo);
    ws.mergeCells(`${columnas[x]}4:${columnas[x + 2]}4`);
    x = x + 3;
  }

  ws.getColumn(`A`).width = "4";
  ws.getColumn(`B`).width = "10";
  ws.getColumn(`C`).width = "36";
  ws.getColumn(`D`).width = "17";

  estilarCeldaHead(ws, "A1", verde);
  estilarCeldaHead(ws, "E1", verdeClaro);
  estilarCeldaHead(ws, "E2", verdeClaro);
  estilarCeldaHead(ws, "E3", verdeClaro);
  estilarCeldaHead(ws, "AA1", verdeClaro);
  estilarCeldaHead(ws, "AA2", verdeClaro);
  estilarCeldaHead(ws, "AA3", verdeClaro);
  estilarCelda(ws, "A4", amarillo);
  estilarCelda(ws, "B4", amarillo);
  estilarCelda(ws, "C4", amarillo);
  estilarCelda(ws, "D4", amarillo);
};

const estilarCelda = (ws, celda, color, bandera) => {
  ws.getCell(celda).fill = {
    type: "gradient",
    gradient: "path",
    center: { left: 0.5, top: 0.5 },
    stops: [
      { position: 0, color: { argb: color } },
      { position: 1, color: { argb: color } },
    ],
  };
  ws.getCell(celda).border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };

  if (bandera) {
    ws.getCell(celda).alignment = { horizontal: "center", vertical: "middle" };
  }
};

const estilarCeldaHead = (ws, celda, color, bandera) => {
  ws.getCell(celda).fill = {
    type: "gradient",
    gradient: "path",
    center: { left: 0.5, top: 0.5 },
    stops: [
      { position: 0, color: { argb: color } },
      { position: 1, color: { argb: color } },
    ],
  };
  ws.getCell(celda).alignment = { horizontal: "center", vertical: "middle" };
  if (bandera) {
    ws.getCell(celda).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  }
};

const observaciones = (detalleAsistencias) => {
  let numAsistencias = detalleAsistencias.length - 1;
  if (detalleAsistencias.length == 0) {
    numAsistencias = 0;
  }
  const objetoRetornar = {
    obs: false,
    obsDescripcion: "- - OK - -",
    posUltimaFechaSalida: numAsistencias,
  };
  if (detalleAsistencias[numAsistencias].horaSalida.length == 0) {
    if (numAsistencias != 0) {
      objetoRetornar.posUltimaFechaSalida = numAsistencias - 1;
    }
  }

  detalleAsistencias.map((asistencia) => {
    if (asistencia.horaSalida.length == 0) {
      objetoRetornar.obs = true;
      objetoRetornar.obsDescripcion = "- Salida -";
    }
  });

  detalleAsistencias.map((asistencia) => {
    if (
      asistencia.temperaturaEntrada >= 37 ||
      asistencia.temperaturaSalida >= 37
    ) {
      objetoRetornar.obs = true;
      objetoRetornar.obsDescripcion = "- FIEBRE -";
    }
  });
  return objetoRetornar;
};

const detalleFecha = async (
  wb,
  diaFinal,
  mes,
  anio,
  listaEmpleados,
  nombreSede
) => {
  for (let i = 0; i < diaFinal; i++) {
    let dia = i + 1;
    if (dia < 10) {
      dia = `0${dia}`;
    }
    let fecha = `${dia}-${mes}-${anio}`;
    let ws = await wb.addWorksheet(fecha);
    ws.getColumn(`A`).width = "4";
    ws.getColumn(`B`).width = "10";
    ws.getColumn(`C`).width = "36";
    ws.getColumn(`D`).width = "16";
    ws.getColumn(`E`).width = "8";
    ws.getColumn(`F`).width = "14";
    ws.getColumn(`G`).width = "8";
    ws.getColumn(`H`).width = "14";
    ws.getColumn(`I`).width = "18";
    if (nombreSede.length != 0) {
      ws.getCell("A1").value = {
        richText: [
          {
            font: { bold: true, size: "20" },
            text: `FUJITA GUMI S.A.C. (${nombreSede.toUpperCase()})`,
          },
        ],
      };
    } else {
      ws.getCell("A1").value = {
        richText: [
          {
            font: { bold: true, size: "20" },
            text: "FUJITA GUMI S.A.C. (GENERAL)",
          },
        ],
      };
    }
    ws.mergeCells("A1:I1");
    ws.getCell("A2").value = {
      richText: [{ font: { bold: true, size: "16" }, text: fecha }],
    };
    ws.mergeCells("A2:I2");
    ws.addRow([
      { richText: [{ font: { bold: true }, text: "N° " }] },
      { richText: [{ font: { bold: true }, text: "DNI" }] },
      { richText: [{ font: { bold: true }, text: "Nombres" }] },
      { richText: [{ font: { bold: true }, text: "Cargo/Esp." }] },
      { richText: [{ font: { bold: true }, text: "Entrada" }] },
      { richText: [{ font: { bold: true }, text: "" }] },
      { richText: [{ font: { bold: true }, text: "Salida" }] },
      { richText: [{ font: { bold: true }, text: "" }] },
      { richText: [{ font: { bold: true }, text: "Sede de asistencia" }] },
    ]);
    ws.mergeCells("E3:F3");
    ws.mergeCells("G3:H3");
    ws.addRow([
      { richText: [{ font: { bold: true }, text: "" }] },
      { richText: [{ font: { bold: true }, text: "" }] },
      { richText: [{ font: { bold: true }, text: "" }] },
      { richText: [{ font: { bold: true }, text: "" }] },
      { richText: [{ font: { bold: true }, text: "Hora" }] },
      { richText: [{ font: { bold: true }, text: "Temperatura" }] },
      { richText: [{ font: { bold: true }, text: "Hora" }] },
      { richText: [{ font: { bold: true }, text: "Temperatura" }] },
      { richText: [{ font: { bold: true }, text: "" }] },
    ]);
    ws.mergeCells("A3:A4");
    ws.mergeCells("B3:B4");
    ws.mergeCells("C3:C4");
    ws.mergeCells("D3:D4");
    ws.mergeCells("I3:I4");

    estilarCeldaHead(ws, "A1", verdeLimon, true);
    estilarCeldaHead(ws, "A2", verdeLimon, true);
    estilarCeldaHead(ws, "A3", verdeLimon, true);
    estilarCeldaHead(ws, "B3", verdeLimon, true);
    estilarCeldaHead(ws, "C3", verdeLimon, true);
    estilarCeldaHead(ws, "D3", verdeLimon, true);
    estilarCeldaHead(ws, "E3", verdeLimon, true);
    estilarCeldaHead(ws, "G3", verdeLimon, true);
    estilarCeldaHead(ws, "I3", verdeLimon, true);
    estilarCeldaHead(ws, "E4", verdeLimon, true);
    estilarCeldaHead(ws, "F4", verdeLimon, true);
    estilarCeldaHead(ws, "G4", verdeLimon, true);
    estilarCeldaHead(ws, "H4", verdeLimon, true);

    let k = 1;
    let c = 1;
    listaEmpleados.map((empleado) => {
      let fila = 4 + c;
      let row = [
        { richText: [{ text: k }] },
        { richText: [{ text: empleado.dni }] },
        {
          richText: [
            {
              text: `${empleado.apellidoPaterno} ${empleado.apellidoMaterno} ${empleado.nombre}`,
            },
          ],
        },
        { richText: [{ text: empleado.especialidad }] },
      ];
      let posicionAsistencia = -1;

      for (let x = 0; x < empleado.asistencia.length; x++) {
        if (empleado.asistencia[x].fechaAsistencia == fecha) {
          posicionAsistencia = x;
          x = empleado.asistencia.length;
        }
      }
      if (posicionAsistencia != -1) {
        row.push({
          richText: [
            {
              text: empleado.asistencia[posicionAsistencia]
                .detalleAsistencias[0].horaEntrada,
            },
          ],
        });
        row.push({
          richText: [
            {
              text: empleado?.asistencia?.[posicionAsistencia]
                ?.detalleAsistencias?.[0]?.temperaturaEntrada
                ? `${empleado.asistencia[posicionAsistencia].detalleAsistencias[0].temperaturaEntrada}°C`
                : "- - -",
            },
          ],
        });
        row.push({
          richText: [
            {
              text: empleado.asistencia[posicionAsistencia]
                .detalleAsistencias[0].horaSalida,
            },
          ],
        });
        row.push({
          richText: [
            {
              text: empleado?.asistencia?.[posicionAsistencia]
                ?.detalleAsistencias?.[0].temperaturaSalida
                ? `${empleado.asistencia[posicionAsistencia].detalleAsistencias[0].temperaturaSalida}°C`
                : "- - -",
            },
          ],
        });
        row.push({
          richText: [
            {
              text: empleado.asistencia[posicionAsistencia]
                .detalleAsistencias[0].sede,
            },
          ],
        });
        ws.addRow(row);
        if (
          empleado.asistencia[posicionAsistencia].detalleAsistencias[0]
            .temperaturaEntrada >= 37
        ) {
          estilarCelda(ws, `F${fila}`, rojo, true);
        } else {
          estilarCelda(ws, `F${fila}`, blanco, true);
        }
        if (
          empleado.asistencia[posicionAsistencia].detalleAsistencias[0]
            .temperaturaSalida >= 37
        ) {
          estilarCelda(ws, `H${fila}`, rojo, true);
        } else {
          estilarCelda(ws, `H${fila}`, blanco, true);
        }
        if (
          empleado.asistencia[posicionAsistencia].detalleAsistencias.length > 1
        ) {
          for (
            let r = 1;
            r <
            empleado.asistencia[posicionAsistencia].detalleAsistencias.length;
            r++
          ) {
            let nuevaRow = [
              { richText: [{ text: k }] },
              { richText: [{ text: "" }] },
              { richText: [{ text: "" }] },
              { richText: [{ text: "" }] },
            ];
            nuevaRow.push({
              richText: [
                {
                  text: empleado.asistencia[posicionAsistencia]
                    .detalleAsistencias[r].horaEntrada,
                },
              ],
            });
            nuevaRow.push({
              richText: [
                {
                  text: empleado?.asistencia?.[posicionAsistencia]
                    ?.detalleAsistencias[r]?.temperaturaEntrada
                    ? `${empleado.asistencia[posicionAsistencia].detalleAsistencias[r].temperaturaEntrada}°C`
                    : "- - -",
                },
              ],
            });
            nuevaRow.push({
              richText: [
                {
                  text: empleado.asistencia[posicionAsistencia]
                    .detalleAsistencias[r].horaSalida,
                },
              ],
            });
            nuevaRow.push({
              richText: [
                {
                  text: empleado?.asistencia?.[posicionAsistencia]
                    ?.detalleAsistencias[r]?.temperaturaSalida
                    ? `${empleado.asistencia[posicionAsistencia].detalleAsistencias[r].temperaturaSalida}°C`
                    : "- - -",
                },
              ],
            });
            nuevaRow.push({
              richText: [
                {
                  text: empleado.asistencia[posicionAsistencia]
                    .detalleAsistencias[r].sede,
                },
              ],
            });
            ws.addRow(nuevaRow);
            let color = blanco;
            if (
              empleado.asistencia[posicionAsistencia].detalleAsistencias[r]
                .temperaturaEntrada >= 37
            ) {
              estilarCelda(ws, `F${fila + r}`, rojo, true);
            } else {
              estilarCelda(ws, `F${fila + r}`, blanco, true);
            }
            if (
              empleado.asistencia[posicionAsistencia].detalleAsistencias[r]
                .temperaturaSalida >= 37
            ) {
              estilarCelda(ws, `H${fila + r}`, rojo, true);
            } else {
              estilarCelda(ws, `H${fila + r}`, blanco, true);
            }
            c++;
            ws.mergeCells(`B${fila + r}:D${fila + r}`);
            estilarCelda(ws, `A${fila + r}`, gris);
            estilarCelda(ws, `B${fila + r}`, gris);
            estilarCelda(ws, `E${fila + r}`, blanco, true);
            estilarCelda(ws, `G${fila + r}`, blanco, true);
            estilarCelda(ws, `I${fila + r}`, blanco, true);
          }
        }
      } else {
        row.push({ richText: [{ text: "- - -" }] });
        row.push({ richText: [{ text: "- - -" }] });
        row.push({ richText: [{ text: "- - -" }] });
        row.push({ richText: [{ text: "- - -" }] });
        row.push({ richText: [{ text: "- - -" }] });
        ws.addRow(row);
        estilarCelda(ws, `F${fila}`, blanco, true);
        estilarCelda(ws, `H${fila}`, blanco, true);
      }
      estilarCelda(ws, `A${fila}`, gris);
      estilarCelda(ws, `B${fila}`, gris);
      estilarCelda(ws, `C${fila}`, gris);
      estilarCelda(ws, `D${fila}`, gris);
      estilarCelda(ws, `E${fila}`, blanco, true);
      estilarCelda(ws, `G${fila}`, blanco, true);
      estilarCelda(ws, `I${fila}`, blanco, true);

      c++;
      k++;
    });
  }
};
