import clienteAxios from "../config/axios";
import { HEADERS } from "../config/constantes";

export const crearEmpleado = async (params) => {
  try {
    const respuesta = await clienteAxios.post("/empleados", params, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const listarEmpleados = async (params) => {
  try {
    const respuesta = await clienteAxios.get(
      `/empleados/estado/${params}`,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const listarEmpleado = async (params) => {
  try {
    const respuesta = await clienteAxios.get(`/empleados/${params}`, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const actualizarEmpleado = async (id, params) => {
  try {
    const respuesta = await clienteAxios.put(
      `/empleados/${id}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const actualizarEstadoEmpleado = async (id) => {
  try {
    const respuesta = await clienteAxios.get(
      `/empleados/modificarEstado/${id}`,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const inicioAsistencia = async (dni, params) => {
  try {
    const respuesta = await clienteAxios.post(
      `/empleados/inicioAsistencia/${dni}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const empleadoAsistencia = async (dni, params) => {
  try {
    const respuesta = await clienteAxios.post(
      `/empleados/asistencia/${dni}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const obtenerEmpleadoAsistencia = async (id, params) => {
  try {
    const respuesta = await clienteAxios.post(
      `/empleados/obtenerAsistencias/${id}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const obtenerEmpleadosAsistencia = async (params) => {
  try {
    const respuesta = await clienteAxios.post(
      `/empleados/obtenerAsistenciasEmpleado`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const modificarAsistencia = async (id, params) => {
  try {
    const respuesta = await clienteAxios.put(
      `/empleados/modificarAsistencia/${id}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const actualizarEstadoAsistencia = async (id, params) => {
  try {
    const respuesta = await clienteAxios.post(
      `/empleados/modificarEstadoAsistencia/${id}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};
