import { Button, Form, Input, InputNumber, notification } from "antd";
import React, { useEffect, useState } from "react";
import { crearEmpleado } from "../../../api/empleados";
import { listarSedes } from "../../../api/sedes";

export default function AddEmpleadoModal(props) {
  const { setIsVisibleModal, setReloadEmpleados } = props;
  const [sedes, setSedes] = useState(null);

  useEffect(() => {
    async function obtenerSedes() {
      let response = await listarSedes();
      setSedes(response.data);
    }
    obtenerSedes();
  }, []);

  const onFinish = async (values) => {
    const response = await crearEmpleado(values);
    setReloadEmpleados(true);

    if (response.code === 200) {
      notification["success"]({
        message: "Éxito",
        description: response.message,
      });
      setIsVisibleModal(false);
    } else if (response.code === 400) {
      notification["error"]({
        message: "Error",
        description: response.message,
      });
    } else {
      notification["warning"]({
        message: "Error",
        description: response.message,
      });
      setIsVisibleModal(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Nombres:"
        name="nombre"
        rules={[
          {
            required: true,
            message: "Este campo es obligatorio.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Apellido paterno"
        name="apellidoPaterno"
        rules={[{ required: true, message: "Este campo es obligatorio." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Apellido materno"
        name="apellidoMaterno"
        rules={[{ required: true, message: "Este campo es obligatorio." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="DNI"
        name="dni"
        rules={[
          { required: true, message: "Este campo es obligatorio." },
          {
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  reject("");
                }
                if (value.length < 7 || value.length > 17) {
                  reject("Este campo debe contener de 6 a 18 digitos");
                } else {
                  resolve();
                }
              });
            },
          },
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      {/* <Form.Item
        label="Sede"
        name="sede"
        rules={[{ required: true, message: "Este campo es obligatorio." }]}
      >
        <Select>
          {sedes !== null
            ? sedes.map((sede) => (
                <Select.Option key={sede._id} value={sede._id}>
                  {sede.nombre}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item> */}

      <Form.Item label="Cargo/Especialidad" name="especialidad">
        <Input />
      </Form.Item>

      <Form.Item label="Dirección" name="direccion">
        <Input />
      </Form.Item>

      <Form.Item label="Teléfono" name="telefono">
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
}
