import {
  HomeOutlined,
  PoweroffOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Image, Layout, Menu, Typography } from "antd";
import React, { useContext, useState } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import logo from "../assets/img/png/fujita_logo.png";
import PrivateRoute from "../components/PrivateRoute";
import { RUTAS } from "../config/constantes";
import Login from "../pages/Login";
import { authContext } from "../providers/AuthContext";
import "./MainLayout.scss";

const { Content, Sider, Header } = Layout;
const { Title } = Typography;

export default function MainLayout(props) {
  const { routes } = props;
  const { setAuthData, auth } = useContext(authContext);
  let [collapsed, setCollapsed] = useState(false);

  let onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onLogOut = () => {
    setAuthData(null);
  };

  if (!auth.data && !auth.loading) {
    return (
      <>
        <Route path="/" component={Login} />
        <Redirect to="/" />
      </>
    );
  }

  if (auth.data && !auth.loading) {
    return (
      <Layout className="site-layout">
        <Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Image className="logo" src={logo} />
            <div className="header">
              <Button
                className="header-button"
                type="primary"
                icon={<PoweroffOutlined />}
                onClick={onLogOut}
              >
                Salir
              </Button>
              <Title
                level={4}
                style={{ color: "white" }}
              >{`${auth.data.nombres} ${auth.data.apellidos}`}</Title>
            </div>
          </div>
        </Header>
        <Layout style={{ minHeight: "90vh" }}>
          <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="logo" />

            {auth.data.tipoUsuario === "admin" ? (
              <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                <Menu.Item key={RUTAS.asistencia} icon={<ScheduleOutlined />}>
                  <Link to={RUTAS.asistencia}>Asistencia</Link>
                </Menu.Item>
                <Menu.Item key={RUTAS.empleados} icon={<UserOutlined />}>
                  <Link to={RUTAS.empleados}>Empleados</Link>
                </Menu.Item>
                <Menu.Item key={RUTAS.sede} icon={<HomeOutlined />}>
                  <Link to={RUTAS.sede}>Sedes</Link>
                </Menu.Item>
                <Menu.Item key={RUTAS.lista} icon={<TeamOutlined />}>
                  <Link to={RUTAS.lista}>Lista de asistencias</Link>
                </Menu.Item>
                <Menu.Item key={RUTAS.usuarios} icon={<TeamOutlined />}>
                  <Link to={RUTAS.usuarios}>Usuarios</Link>
                </Menu.Item>
              </Menu>
            ) : (
              <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                <Menu.Item key={RUTAS.asistencia} icon={<ScheduleOutlined />}>
                  <Link to={RUTAS.asistencia}>Asistencia</Link>
                </Menu.Item>
              </Menu>
            )}
          </Sider>
          <Content style={{ margin: "20px" }}>
            <div className="site-layout-background">
              <LoadRoutes routes={routes} />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }

  return null;
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
