import {
  Button,
  Descriptions,
  Form,
  Input,
  TimePicker,
  notification,
} from "antd";
import React, { useEffect } from "react";
import { modificarAsistencia } from "../../../api/empleados";

import moment from "moment";
const format = "HH:mm";

export default function EditAsistencia(props) {
  const { setIsVisibleModal, setReload, datos, showTemperature } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      form.setFieldsValue({
        nombre: datos.nombre,
        dni: datos.dni,
        sede: datos.sede,
        temperaturaEntrada: datos.temperaturaEntrada,
        temperaturaSalida: datos.temperaturaSalida,
      });
    }
    fetchData();
  }, [datos._id]);

  const onFinish = async (values) => {
    const objeto = {
      fechaAsistencia: datos.fechaAsistencia,
      horaEntrada: datos.horaEntrada || "",
      horaSalida: datos.horaSalida || "",
      temperaturaEntrada: values.temperaturaEntrada,
      temperaturaSalida: values.temperaturaSalida,
      posicion: datos.i,
    };
    if (values.horaEntrada !== undefined) {
      objeto.horaEntrada = values.horaEntrada
        ? moment(values.horaEntrada).format("HH:mm")
        : "";
    }
    if (values.horaSalida !== undefined) {
      objeto.horaSalida = values.horaSalida
        ? moment(values.horaSalida).format("HH:mm")
        : "";
    }

    let response = await modificarAsistencia(datos._id, objeto);
    setReload(true);

    if (response.code === 200) {
      notification["success"]({
        message: "Éxito",
        description: response.message,
      });
      setIsVisibleModal(false);
    } else if (response.code === 400) {
      notification["error"]({
        message: "Error",
        description: response.message,
      });
      setIsVisibleModal(true);
    } else {
      notification["warning"]({
        message: "Error",
        description: response.message,
      });
      setIsVisibleModal(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  return (
    <>
      <Descriptions bordered layout={"horizontal"}>
        <Descriptions.Item label="Empleado">{datos.nombre}</Descriptions.Item>
      </Descriptions>
      <Descriptions bordered layout={"horizontal"}>
        <Descriptions.Item label="DNI">{datos.dni}</Descriptions.Item>
        <Descriptions.Item label="Sede">{datos.sede}</Descriptions.Item>
      </Descriptions>

      <br />
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Form.Item label="Hora de entrada" name="horaEntrada">
          <TimePicker
            defaultValue={moment(
              datos.horaEntrada.length !== 0 ? datos.horaEntrada : "00:00",
              format
            )}
            format={format}
          />
        </Form.Item>

        {showTemperature && (
          <Form.Item label="Temperatura de entrada" name="temperaturaEntrada">
            <Input type="number" step={0.1} max="100" addonAfter="°C" />
          </Form.Item>
        )}

        <Form.Item label="Hora de salida" name="horaSalida">
          <TimePicker
            defaultValue={moment(
              datos.horaSalida.length !== 0 ? datos.horaSalida : "00:00",
              format
            )}
            format={format}
          />
        </Form.Item>

        {showTemperature && (
          <Form.Item label="Temperatura de salida" name="temperaturaSalida">
            <Input type="number" step={0.1} max="100" addonAfter="°C" />
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
