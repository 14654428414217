import { FileExcelTwoTone } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { obtenerEmpleadosAsistencia } from "../../../api/empleados";
import { listarSedes } from "../../../api/sedes";
import { crearExcel } from "../../../utils/reportes/crearExcel";
import { generarPDFMensual } from "../../../utils/reportes/crearPDF";

export default function AsistenciaReportesExcel() {
  const [sedes, setSedes] = useState(null);

  useEffect(() => {
    async function listarSedesAsistencia() {
      let response = await listarSedes(true);
      setSedes(response.data);
    }
    listarSedesAsistencia();
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinish = async (values) => {
    const fecha = moment(values.fechas);
    const idSede = values.sede;
    let nombreSede = "";
    for (let i in sedes) {
      if (idSede == sedes[i]._id) {
        nombreSede = sedes[i].nombre;
      }
    }
    const fechaSplit = moment(values.fechas).format("MM-YYYY").split("-");
    const mes = fechaSplit[0];
    const anio = fechaSplit[1];
    const fechaInicio = fecha.startOf("month").format("DD-MM-YYYY");
    const fechaFinal = fecha.endOf("month").format("DD-MM-YYYY");
    const listaEmpleados = await obtenerEmpleadosAsistencia({
      nombreSede,
      fechaInicio,
      fechaFinal,
    });

    const myProps = {
      mes,
      anio,
      fechaInicio,
      nombreSede,
      fechaFinal,
      listaEmpleados: listaEmpleados.data,
    };
    if (values.tipo == "Excel") {
      await crearExcel(myProps);
    } else {
      await generarPDFMensual(myProps, values.temperature);
    }
  };

  return (
    <Form {...layout} name="basic" onFinish={onFinish}>
      <Form.Item
        label="Seleccionar mes:"
        name="fechas"
        rules={[
          {
            required: true,
            message: "Seleccione el mes y el año.",
          },
        ]}
      >
        <DatePicker picker="month" />
      </Form.Item>
      <Form.Item name="sede" label="Sede">
        <Select>
          <Select.Option value="">Todas las sedes</Select.Option>
          {sedes !== null
            ? sedes.map((sede) => (
                <Select.Option key={sede._id} value={sede._id}>
                  {sede.nombre}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item>
      <Form.Item
        name="tipo"
        label="Formato de reporte"
        rules={[{ required: true }]}
      >
        <Radio.Group options={["PDF", "Excel"]} value="Excel" />
      </Form.Item>
      <Form.Item
        name="temperature"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox>Agregar temperaturas</Checkbox>
      </Form.Item>
      <Row justify="center">
        <Form.Item>
          <Space>
            <Button htmlType="submit" icon={<FileExcelTwoTone />}>
              Reporte mensual
            </Button>
          </Space>
        </Form.Item>
      </Row>
    </Form>
  );
}
