import React from 'react'
import {Switch} from 'react-router-dom';
import PublicRoute from '../components/PublicRoute'

export default function BasicLayout(props) {
    const { routes } = props;
    return (
        <>
            <LoadRoutes routes={routes}/>
        </>
    )
}

function LoadRoutes({routes}) {
    return (
        <Switch>
          {routes.map((route, index) => (
            <PublicRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </Switch>
      );
}
