import React, { useState, useEffect } from "react";
import { Row, Col, Button, PageHeader, List, Divider } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Modal from "../../components/Modal";
import { listarSedes } from "../../api/sedes";
import AddSedeModal from "../../components/Sede/AddSedeModal";
import EditSedeModal from "../../components/Sede/EditSedeModal";
import "./Sede.scss";

export default function Sede() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reloadSede, setReloadSede] = useState(false);
  const [sedes, setSedes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await listarSedes();
      setSedes(response.data)
      setReloadSede(false)
      setIsLoading(false);
    }
    fetchData();
  }, [reloadSede]);

  const addSede = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar sede");
    setModalContent(<AddSedeModal setIsVisibleModal={setIsVisibleModal} setReloadSede={setReloadSede}/>);
  };

  const editSede = (key) => {
    setIsVisibleModal(true);
    setModalTitle("Editar sede");
    setModalContent(
      <EditSedeModal setIsVisibleModal={setIsVisibleModal} setReloadSede={setReloadSede} idSede={key} />
    );
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Sedes"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<HomeOutlined />}
            onClick={addSede}
          >
            Nueva sede
          </Button>,
        ]}
      >
        <Divider style={{ marginTop: "0px" }} />
        <Row>
          <Col lg={1} />
          <Col lg={22}>
            <List
              loading={isLoading}
              itemLayout="horizontal"
              dataSource={sedes}
              bordered={true}
              pagination={{
                onChange: (page) => {},
                pageSize: 10,
              }}
              renderItem={(item) => (
                <List.Item
                  actions={[<Button type="primary" onClick={() => editSede(item._id)}>Modificar</Button>]}
                >
                  <List.Item.Meta
                    title={`${item.nombre ? item.nombre : ''}`}
                    description={`${item.direccion ? item.direccion : ''}`}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col lg={1} />
        </Row>
      </PageHeader>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        footer={false}
      >
        {modalContent}
      </Modal>
    </>
  );
}
