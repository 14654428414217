import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { RUTAS } from "../config/constantes";
import Asistencia from "../pages/Asistencia";
import { authContext } from "../providers/AuthContext";
import { expiroToken } from "../utils/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(authContext);
  const { loading } = auth;

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }
  // if loading is set to true (when our function useEffect(() => {}, []) is not executed), we are rendering a loading component;

  /* 
  if (expiroToken()) {
    notification["info"]({
      message: "La sesión ha expirado, vuelva a ingresar."
    });
  }*/

  if (auth.data.tipoUsuario === "user" && !expiroToken()) {
    return (
      <>
        <Route path={RUTAS.asistencia} component={Asistencia} />
        <Redirect to={RUTAS.asistencia} />
      </>
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        expiroToken() ? (
          <Redirect to={RUTAS.login} />
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  );
  /*  we are spreading routeProps to be able to access this routeProps in the component. */
};

export default PrivateRoute;
