import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Descriptions, notification } from "antd";

import { empleadoAsistencia } from "../../../api/empleados";

export default function AsistenciaModal(props) {
  const { estado, dataUser, fecha, hora, sedeAsistencia, setIsVisibleModal } =
    props;

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [props.isVisible]);

  const onFinish = async (values) => {
    const { temperatura } = values;
    const asistencia = {
      fecha: fecha,
      hora: hora,
      temperatura: temperatura,
      sede: sedeAsistencia,
    };

    const response = await empleadoAsistencia(dataUser.dni, asistencia);

    if (response.code === 200) {
      notification["success"]({
        message: "Éxito",
        description: response.message,
      });
      setIsVisibleModal(false);
    } else if (response.code === 400) {
      notification["error"]({
        message: "Error",
        description: response.message,
      });
    } else {
      notification["warning"]({
        message: "Error",
        description: response.message,
      });
      setIsVisibleModal(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  return (
    <>
      <Descriptions bordered layout={"horizontal"}>
        <Descriptions.Item label="Empleado">
          {dataUser.nombre} {dataUser.apellidoPaterno}{" "}
          {dataUser.apellidoMaterno}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered layout={"horizontal"}>
        <Descriptions.Item label="DNI">{dataUser.dni}</Descriptions.Item>
        <Descriptions.Item label="Cargo/Esp.">
          {dataUser.especialidad}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Form
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Temperatura" name="temperatura">
          <Input type="number" step={0.1} max="100" addonAfter="°C" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            ref={buttonRef}
            type={estado ? "primary" : "danger"}
            htmlType="submit"
            autoFocus
          >
            {estado ? "Entrada" : "Salida"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
