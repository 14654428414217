export const RUTAS = {
  login: "/",
  asistencia: "/asistencia",
  empleados: "/asistencia/empleados",
  sede: "/asistencia/sede",
  usuarios: "/asistencia/usuarios",
  lista: "/asistencia/lista",
};

export const HEADERS = {
  headers: {
    Authorization: JSON.parse(localStorage.getItem("authData"))
      ? JSON.parse(localStorage.getItem("authData")).token
      : null,
  },
};
