import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generarPDF = (empleado, asistencias, addTemperature) => {
  const json = generarJson(empleado, asistencias, addTemperature);
  pdfMake.createPdf(json).open();
};

export const generarPDFMensual = (props, addTemperature) => {
  const json = generarJsonMensual(props, addTemperature);
  pdfMake.createPdf(json).open();
};

const generarJsonMensual = (props, addTemperature) => {
  let sede = "GENERAL";
  let ruc = "20100249350";
  let fechas = `DEL ${props.fechaInicio} AL ${props.fechaFinal}`;
  if (props.nombreSede != 0) {
    sede = props.nombreSede;
  }

  const tableBody = addTemperature
    ? [
        { text: "N°", style: "tableHeader" },
        { text: "Fecha", style: "tableHeader" },
        { text: "DNI", style: "tableHeader" },
        { text: "Apellidos y nombres", style: "tableHeader" },
        { text: "Cargo/Esp.", style: "tableHeader" },
        { text: "H. Entrada", style: "tableHeader" },
        { text: "T. Entrada", style: "tableHeader" },
        { text: "H. Salida", style: "tableHeader" },
        { text: "T. Salida", style: "tableHeader" },
      ]
    : [
        { text: "N°", style: "tableHeader" },
        { text: "Fecha", style: "tableHeader" },
        { text: "DNI", style: "tableHeader" },
        { text: "Apellidos y nombres", style: "tableHeader" },
        { text: "Cargo/Esp.", style: "tableHeader" },
        { text: "H. Entrada", style: "tableHeader" },
        { text: "H. Salida", style: "tableHeader" },
      ];

  let json = {
    content: [
      {
        stack: ["Reporte de asistencias del personal"],
        style: "header",
      },
      {
        columns: [
          { bold: true, width: 100, fontSize: 9, text: "Empresa: " },
          { bold: true, width: 100, fontSize: 9, text: `FUJITA GUMI S.A.C.` },
          { bold: true, width: 100, fontSize: 9, text: "      " },
          { bold: true, width: 100, fontSize: 9, text: "Sede: " },
          { bold: true, width: 100, fontSize: 9, text: sede },
        ],
      },
      {
        columns: [
          { bold: true, width: 100, fontSize: 9, text: "RUC: " },
          { bold: true, width: 100, fontSize: 9, text: ruc },
          { bold: true, width: 100, fontSize: 9, text: "      " },
          { bold: true, width: 150, fontSize: 9, text: fechas },
          { bold: true, width: 50, fontSize: 9, text: "" },
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: [tableBody],
        },
        layout: "headerLineOnly",
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 15,
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: "black",
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
      tableContent: {
        fontSize: 7.5,
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
    },
  };
  listaEmpleadosMensual(props.listaEmpleados, json, sede, addTemperature);
  return json;
};

const listaEmpleadosMensual = (listaEmpleados, json, sede, addTemperature) => {
  let numeroLista = 1;
  if (sede == "GENERAL") {
    json.content[3].table.body[0].push({
      text: "Sede asistencia",
      style: "tableHeader",
    });
  }
  listaEmpleados.forEach((empleado) => {
    let nombre =
      `${empleado.apellidoPaterno} ${empleado.apellidoMaterno} ${empleado.nombre}`.toUpperCase();
    let dni = empleado.dni;
    let especialidad = empleado.especialidad;
    let max = empleado.asistencia.length;
    for (let i = max - 1; i >= 0; i--) {
      let asistencia = empleado.asistencia[i];
      let fechaAsistencia = asistencia.fechaAsistencia;
      asistencia.detalleAsistencias
        .filter((v) => !v.disabled)
        .forEach((detalleAsistencias) => {
          let row = addTemperature
            ? [
                { style: "tableContent", text: numeroLista },
                { style: "tableContent", text: fechaAsistencia },
                { style: "tableContent", text: dni },
                { style: "tableContent", text: nombre },
                { style: "tableContent", text: especialidad },
                { style: "tableContent", text: detalleAsistencias.horaEntrada },
                {
                  style: "tableContent",
                  text: detalleAsistencias.temperaturaEntrada
                    ? `${detalleAsistencias.temperaturaEntrada}°C`
                    : "- - -",
                },
                { style: "tableContent", text: detalleAsistencias.horaSalida },
                {
                  style: "tableContent",
                  text: detalleAsistencias.temperaturaSalida
                    ? `${detalleAsistencias.temperaturaSalida}°C`
                    : "- - -",
                },
              ]
            : [
                { style: "tableContent", text: numeroLista },
                { style: "tableContent", text: fechaAsistencia },
                { style: "tableContent", text: dni },
                { style: "tableContent", text: nombre },
                { style: "tableContent", text: especialidad },
                { style: "tableContent", text: detalleAsistencias.horaEntrada },
                { style: "tableContent", text: detalleAsistencias.horaSalida },
              ];
          if (sede == "GENERAL") {
            row = [
              ...row,
              { style: "tableContent", text: detalleAsistencias.sede },
            ];
          }
          json.content[3].table.body.push(row);
        });
    }
    numeroLista++;
  });
};

const generarJson = (empleado, asistencias, addTemperature) => {
  const nombre = `${empleado.nombre} ${empleado.apellidoPaterno} ${empleado.apellidoMaterno}`;
  const tableWithDates = {
    widths: [80, 94, 50, 94, 50, 94],
    body: [
      [
        { text: "Fecha", style: "tableHeader", alignment: "center" },
        { text: "Sede", style: "tableHeader", alignment: "center" },
        { text: "Entrada", style: "tableHeader", alignment: "center" },
        {
          text: "Temperatura de entrada",
          style: "tableHeader",
          alignment: "center",
        },
        { text: "Salida", style: "tableHeader", alignment: "center" },
        {
          text: "Temperatura de salida",
          style: "tableHeader",
          alignment: "center",
        },
      ],
    ],
  };

  let json = {
    content: [
      {
        stack: ["Reporte de asistencias", { text: nombre, style: "subheader" }],
        style: "header",
      },
      {
        style: "pointMargin",
        table: addTemperature
          ? tableWithDates
          : {
              widths: [80, 160, 120, 120],
              body: [
                [
                  { text: "Fecha", style: "tableHeader", alignment: "center" },
                  { text: "Sede", style: "tableHeader", alignment: "center" },
                  {
                    text: "Entrada",
                    style: "tableHeader",
                    alignment: "center",
                  },
                  { text: "Salida", style: "tableHeader", alignment: "center" },
                ],
              ],
            },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 40],
      },
      subheader: {
        fontSize: 15,
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
      tableContent: {
        fontSize: 11.5,
      },
    },
  };
  listarAsistencia(asistencias, json, addTemperature);
  return json;
};

const listarAsistencia = (listaAsistecia, json, addTemperature) => {
  listaAsistecia.forEach((asistencia) => {
    const asistenciasValidas = asistencia.detalleAsistencias.filter(
      (v) => !v.disabled
    );
    for (let i in asistenciasValidas) {
      let fechaAsistencia = asistencia.fechaAsistencia;
      const linea = addTemperature
        ? [
            {
              text: fechaAsistencia,
              style: "tableContent",
              alignment: "center",
            },
            {
              text: asistenciasValidas[i].sede,
              style: "tableContent",
              alignment: "center",
            },
            {
              text: asistenciasValidas[i].horaEntrada,
              style: "tableContent",
              alignment: "right",
            },
            {
              text: asistenciasValidas?.[i]?.temperaturaEntrada
                ? `${asistenciasValidas[i].temperaturaEntrada}°C`
                : "- - --",
              style: "tableContent",
              alignment: "right",
            },
            {
              text: asistenciasValidas[i].horaSalida,
              style: "tableContent",
              alignment: "right",
            },
            {
              text: asistenciasValidas?.[i]?.temperaturaSalida
                ? `${asistenciasValidas[i].temperaturaSalida}°C`
                : "- - --",
              style: "tableContent",
              alignment: "right",
            },
          ]
        : [
            {
              text: fechaAsistencia,
              style: "tableContent",
              alignment: "center",
            },
            {
              text: asistenciasValidas[i].sede,
              style: "tableContent",
              alignment: "center",
            },
            {
              text: asistenciasValidas[i].horaEntrada,
              style: "tableContent",
              alignment: "right",
            },
            {
              text: asistenciasValidas[i].horaSalida,
              style: "tableContent",
              alignment: "right",
            },
          ];
      json.content[1].table.body.push(linea);
    }
  });
};
