import clienteAxios from "../config/axios";
import { HEADERS } from "../config/constantes";

export const crearSede = async (params) => {
  try {
    const respuesta = await clienteAxios.post("/sedes", params, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const listarSedes = async (params) => {
  try {
    const respuesta = await clienteAxios.get("/sedes", HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const listarSede = async (params) => {
  try {
    const respuesta = await clienteAxios.get(`/sedes/${params}`, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const actualizarSede = async (id, params) => {
  try {
    const respuesta = await clienteAxios.put(`/sedes/${id}`, params, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
}

