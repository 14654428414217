import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  PageHeader,
  Divider,
  Space,
  Table,
  Modal as ModalAntd,
  notification,
} from "antd";
import { HomeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Modal from "../../components/Modal";
import { actualizarEstadoUsuario, listaUsuarios } from "../../api/usuarios";
import AddUsuarioModal from "../../components/Usuarios/AddUsuarioModal";
import EditUsiarioModal from "../../components/Usuarios/EditUsiarioModal";
import "./Usuarios.scss";

const { confirm } = ModalAntd;

export default function Usuario() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reloadUsuario, setReloadUsuario] = useState(false);
  const [baseData, setBaseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await listaUsuarios();
      if (response) {
        let newArr = response.data
          .filter((item) => !item.disabled)
          .map(function (item) {
            return {
              _id: item._id,
              usuario: item.usuario,
              nombre: `${item.apellidos} ${item.nombres}`,
              sede: item?.sede?.nombre,
              tipoUsuario: item?.tipoUsuario,
            };
          });
        setBaseData(newArr);
        setIsLoading(false);
      }
      setReloadUsuario(false);
    }
    fetchData();
  }, [reloadUsuario]);

  const addUsuario = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar usuario");
    setModalContent(
      <AddUsuarioModal
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsuario={setReloadUsuario}
      />
    );
  };

  const editUsuario = (key) => {
    setIsVisibleModal(true);
    setModalTitle("Editar usuario");
    setModalContent(
      <EditUsiarioModal
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsuario={setReloadUsuario}
        idUsuario={key}
      />
    );
  };

  const removeUsuario = async (id, nombre) => {
    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${nombre}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        let response = await actualizarEstadoUsuario(id);
        if (response.code === 200) {
          notification["success"]({
            message: "Éxito",
            description: response.message,
          });
          setIsVisibleModal(false);
        } else if (response.code === 400) {
          notification["error"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["warning"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        }
        setReloadUsuario(true);
      },
    });
  };

  const columns = [
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",
      sorter: (a, b) => a.usuario.localeCompare(b.usuario),
      sortDirections: ["descend", "ascend"],
      render: (text) => text,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"],
      render: (text) => text,
    },
    {
      title: "Sede",
      dataIndex: "sede",
      key: "sede",
      sorter: (a, b) => a.sede.localeCompare(b.sede),
      sortDirections: ["descend", "ascend"],
      render: (text) => text,
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editUsuario(record._id)}
          />
          {record.tipoUsuario !== "admin" && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              onClick={async () =>
                await removeUsuario(record._id, record.nombre)
              }
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Usuarios"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<HomeOutlined />}
            onClick={addUsuario}
          >
            Nuevo usuario
          </Button>,
        ]}
      >
        <Divider style={{ marginTop: "0px" }} />
        <Row>
          <Col lg={1} />
          <Col lg={22}>
            <Table
              bordered
              columns={columns}
              dataSource={baseData}
              rowKey={(record) => record._id}
              loading={isLoading}
            />
          </Col>
          <Col lg={1} />
        </Row>
      </PageHeader>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        footer={false}
      >
        {modalContent}
      </Modal>
    </>
  );
}
