import clienteAxios from "../config/axios";
import { HEADERS } from "../config/constantes";

export const login = async (params) => {
  try {
    const respuesta = await clienteAxios.post("/usuarios/login", params);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const registrarUsuario = async (params) => {
  try {
    const respuesta = await clienteAxios.post("/usuarios/", params, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const listaUsuarios = async () => {
  try {
    const respuesta = await clienteAxios.get("/usuarios/", HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const listaUsuario = async (id) => {
  try {
    const respuesta = await clienteAxios.get(`/usuarios/${id}`, HEADERS);
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const modificarUsuario = async (id, params) => {
  try {
    const respuesta = await clienteAxios.put(
      `/usuarios/${id}`,
      params,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};

export const actualizarEstadoUsuario = async (id) => {
  try {
    const respuesta = await clienteAxios.get(
      `/usuarios/modificarEstado/${id}`,
      HEADERS
    );
    return respuesta.data;
  } catch (error) {
    return error.response.data;
  }
};
