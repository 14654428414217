import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Switch,
  Modal as ModalAntd,
  PageHeader,
  notification,
  Input,
} from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { listarEmpleados, actualizarEstadoEmpleado } from "../../api/empleados";
import AddEmpleadoModal from "../../components/Empleados/AddEmpleadoModal";
import EditEmpleadoModal from "../../components/Empleados/EditEmpleadoModal";
import Modal from "../../components/Modal";
import "./styles.css";

const { confirm } = ModalAntd;

export default function Empleados() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [viewUsersActives, setViewUsersActives] = useState(true);
  const [reloadEmpleados, setReloadEmpleados] = useState(false);
  const [baseData, setBaseData] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function listarEmpleadosActivos() {
      let response = await listarEmpleados(true);
      if (response) {
        let newArr = response.data.map(function (item) {
          return {
            _id: item._id,
            nombre: `${item.apellidoPaterno} ${item.apellidoMaterno} ${item.nombre}`,
            dni: item.dni,
          };
        });
        setBaseData(newArr);
        setIsLoading(false);
      }
    }

    async function listarEmpleadosInactivos() {
      let response = await listarEmpleados(false);
      let newArr = response.data.map(function (item) {
        return {
          _id: item._id,
          nombre: `${item.apellidoPaterno} ${item.apellidoMaterno} ${item.nombre}`,
          dni: item.dni,
          sede: item?.sede?.nombre,
        };
      });
      setBaseData(newArr);
    }

    if (viewUsersActives) {
      listarEmpleadosActivos();
    } else {
      listarEmpleadosInactivos();
    }

    setReloadEmpleados(false);
  }, [reloadEmpleados, viewUsersActives]);

  const addEmpleado = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar empleado");
    setModalContent(
      <AddEmpleadoModal
        setIsVisibleModal={setIsVisibleModal}
        setReloadEmpleados={setReloadEmpleados}
      />
    );
  };

  const editEmpleado = (key) => {
    setIsVisibleModal(true);
    setModalTitle("Editar empleado");
    setModalContent(
      <EditEmpleadoModal
        setIsVisibleModal={setIsVisibleModal}
        setReloadEmpleados={setReloadEmpleados}
        idEmpleado={key}
      />
    );
  };

  const modalDeleteEmpleado = async (id, nombre) => {
    confirm({
      title: "Eliminando empleado",
      content: `¿Estas seguro que quieres eliminar a ${nombre}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        let response = await actualizarEstadoEmpleado(id);
        if (response.code === 200) {
          notification["success"]({
            message: "Éxito",
            description: response.message,
          });
          setIsVisibleModal(false);
        } else if (response.code === 400) {
          notification["error"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["warning"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        }
        setReloadEmpleados(true);
      },
    });
  };

  const modalActivarEmpleado = (id, nombre) => {
    confirm({
      title: "Activar empleado",
      content: `¿Estas seguro de activar a ${nombre}?`,
      okText: "Activar",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        let response = await actualizarEstadoEmpleado(id);

        if (response.code === 200) {
          notification["success"]({
            message: "Éxito",
            description: response.message,
          });
          setIsVisibleModal(false);
        } else if (response.code === 400) {
          notification["error"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["warning"]({
            message: "Error",
            description: response.message,
          });
          setIsVisibleModal(true);
        }
        setReloadEmpleados(true);
      },
    });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"],
      render: (text) => text,
    },
    {
      title: "DNI",
      dataIndex: "dni",
      key: "dni",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {viewUsersActives ? (
            <>
              {" "}
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => editEmpleado(record._id)}
              />
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={async () =>
                  await modalDeleteEmpleado(record._id, record.nombre)
                }
              />
            </>
          ) : (
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => modalActivarEmpleado(record._id, record.nombre)}
            />
          )}
        </Space>
      ),
    },
  ];

  const search = (value) => {
    if (value !== "") {
      const filterTable = baseData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilterTable(filterTable);
    } else {
      setFilterTable(null);
    }
  };

  return (
    <>
      <PageHeader className="site-page-header" title="Empleados" />

      <Row>
        <Col lg={1} />
        <Col lg={22}>
          <div className="list-users-header">
            <Space>
              <Switch
                defaultChecked
                onChange={() => {
                  setViewUsersActives(!viewUsersActives);
                  setFilterTable(null);
                }}
              />
              <span>
                {viewUsersActives ? "Empleados activos" : "Empleados inactivos"}
              </span>
            </Space>
            <Button type="primary" onClick={addEmpleado}>
              Nuevo empleado
            </Button>
          </div>
          <Input.Search
            style={{ margin: "0 0 10px 0" }}
            placeholder="Buscar..."
            enterButton
            onSearch={search}
          />
          <Table
            bordered
            columns={columns}
            dataSource={filterTable == null ? baseData : filterTable}
            rowKey={(record) => record._id}
            loading={isLoading}
          />
        </Col>
        <Col lg={1} />
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        footer={false}
      >
        {modalContent}
      </Modal>
    </>
  );
}
