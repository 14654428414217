import React, { useState, useEffect } from "react";
import { DatePicker, Form, Button, Space, Row, Select, Checkbox } from "antd";
import moment from "moment";
import { FilePdfTwoTone } from "@ant-design/icons";
import {
  listarEmpleados,
  obtenerEmpleadoAsistencia,
  listarEmpleado,
} from "../../../api/empleados";
import { generarPDF } from "../../../utils/reportes/crearPDF";

const { RangePicker } = DatePicker;

export default function AsistenciaReportesPDF() {
  const [empleados, setEmpleados] = useState(null);

  useEffect(() => {
    async function listarEmpleadosAsistencia() {
      let response = await listarEmpleados(true);
      setEmpleados(response.data);
    }
    listarEmpleadosAsistencia();
  }, []);

  async function onFinish(values) {
    const id = values.empleado;
    const fechaInicio = moment(values.fechas[0]).format("DD-MM-YYYY");
    const fechaFinal = moment(values.fechas[1]).format("DD-MM-YYYY");
    const rangoFechas = {
      fechaInicio: fechaInicio,
      fechaFinal: fechaFinal,
    };
    const empleado = await listarEmpleado(id);
    const asistencias = await obtenerEmpleadoAsistencia(id, rangoFechas);
    generarPDF(empleado.data[0], asistencias.data, values.temperature);
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <>
      <Form {...layout} name="basic" onFinish={onFinish}>
        <Form.Item
          label="Seleccione fecha:"
          name="fechas"
          rules={[
            {
              required: true,
              message: "Ingrese el rango de fechas.",
            },
          ]}
        >
          <RangePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name="empleado"
          label="Empleado"
          rules={[{ required: true }]}
        >
          <Select>
            {empleados !== null
              ? empleados.map((empleado) => (
                  <Select.Option key={empleado._id} value={empleado._id}>
                    {empleado.nombre} {empleado.apellidoPaterno}{" "}
                    {empleado.apellidoMaterno}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          name="temperature"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>Agregar temperaturas</Checkbox>
        </Form.Item>

        <Row justify="center">
          <Form.Item>
            <Space>
              <Button htmlType="submit" icon={<FilePdfTwoTone />}>
                Reporte PDF
              </Button>
            </Space>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
}
