import React, { useState, useContext } from "react";
import { PageHeader, Form, Input, Button, Row, Col, notification } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import Clock from "react-live-clock";
import Modal from "../../components/Modal";
import AsistenciaModal from "../../components/Asistencia/AsistenciaModal";
import AsistenciaReportesPDF from "../../components/Asistencia/AsistenciaReportesPDF";
import AsistenciaReportesExcel from "../../components/Asistencia/AsistenciaReportesExcel";
import { authContext } from "../../providers/AuthContext";

import { inicioAsistencia } from "../../api/empleados";

export default function Asistencia() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const { auth } = useContext(authContext);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const sedeAsistencia = JSON.parse(localStorage.getItem("authData")).sede
      .nombre;
    const response = await inicioAsistencia(values, { sede: sedeAsistencia });
    form.resetFields();
    if (response.code === 200) {
      const asistenciaEmpleado = response.data[0];
      const estado = asistenciaEmpleado.estado;
      const hora = asistenciaEmpleado.hora;
      const fecha = asistenciaEmpleado.fecha;
      setIsVisibleModal(true);
      setModalTitle("Asistencia");

      setModalContent(
        <AsistenciaModal
          setIsVisibleModal={setIsVisibleModal}
          estado={estado}
          dataUser={asistenciaEmpleado}
          hora={hora}
          fecha={fecha}
          sedeAsistencia={sedeAsistencia}
        />
      );
    } else if (response.code === 400) {
      notification["error"]({
        message: "Error",
        description: response.message,
      });
    }
  };

  const abrirModalReportesPDF = () => {
    setIsVisibleModal(true);
    setModalTitle("Reporte empleado");
    setModalContent(
      <AsistenciaReportesPDF setIsVisibleModal={setIsVisibleModal} />
    );
  };

  const abrirModalReportesExcel = () => {
    setIsVisibleModal(true);
    setModalTitle("Reporte mensual");
    setModalContent(
      <AsistenciaReportesExcel setIsVisibleModal={setIsVisibleModal} />
    );
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Asistencia"
        extra={
          <>
            {auth.data.tipoUsuario === "admin" ? (
              <>
                <Button
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={abrirModalReportesPDF}
                >
                  Reporte empleado
                </Button>
                <Button
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={abrirModalReportesExcel}
                >
                  Reporte mensual
                </Button>{" "}
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
      <Row>
        <Col span={24}>
          <Form {...layout} name="basic" form={form}>
            <Form.Item
              label="DNI"
              name="dni"
              rules={[
                {
                  required: true,
                  message: "Porfavor ingresa un DNI.",
                  max: 8,
                },
              ]}
            >
              <Input.Search
                autoFocus
                type="number"
                onSearch={onFinish}
                enterButton
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Clock
            format={"h:mm:ss a"}
            style={{ fontSize: "7em" }}
            ticking={true}
          />
        </Col>
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        footer={false}
      >
        {modalContent}
      </Modal>
    </>
  );
}
