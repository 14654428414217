import React from "react";
import { Form, Input, Button, TimePicker, notification } from "antd";
import { crearSede } from "../../../api/sedes";
import moment from "moment";
import "./AddSedeModal.scss";

export default function AddSedeModal(props) {
  const { setIsVisibleModal, setReloadSede } = props;
  const format = "HH:mm";

  const onFinish = async (values) => {
    values.horaEntrada = moment(values.horaEntrada).format("HH:mm");
    values.horaSalida = moment(values.horaSalida).format("HH:mm");
    let response = await crearSede(values);
    setIsVisibleModal(false);
    setReloadSede(true);

    if (response.code === 200) {
      notification["success"]({
        message: "Éxito",
        description: response.message,
      });
    } else if (response.code === 400) {
      notification["error"]({
        message: "Error",
        description: response.message,
      });
    } else {
      notification["warning"]({
        message: "Error",
        description: response.message,
      });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  
  return (
    <Form {...layout} name="basic" onFinish={onFinish} initialValues={{}}>
      <Form.Item
        label="Sede:"
        name="nombre"
        rules={[
          {
            required: true,
            message: "Porfavor ingresa el nombre de la sede.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Dirección"
        name="direccion"
        rules={[{ required: true, message: "Porfavor ingresa la dirección." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Hora de entrada"
        name="horaEntrada"
        rules={[
          { required: true, message: "Porfavor ingresa la hora de entrada" },
        ]}
      >
        <TimePicker format={format} />
      </Form.Item>

      <Form.Item
        label="Hora de salida"
        name="horaSalida"
        rules={[
          { required: true, message: "Porfavor ingresa la hora de salida" },
        ]}
      >
        <TimePicker format={format} />
      </Form.Item>

      <Form.Item className="site-page-button">
        <div className="site-page-button">
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
