import MainLayout from "../layouts/MainLayout";
import BasicLayout from "../layouts/BasicLayout";
import Login from "../pages/Login";
import Asistencia from "../pages/Asistencia";
import Empleados from "../pages/Empleados";
import Sede from "../pages/Sede";
import Usuarios from "../pages/Usuarios";
import ListaAsistencia from "../pages/ListaAsistencia";
import { Error404 } from "../pages/Error404";
import { RUTAS } from "../config/constantes";

const routes = [
  {
    path: RUTAS.asistencia,
    component: MainLayout,
    exact: false,
    routes: [
      {
        path: RUTAS.asistencia,
        component: Asistencia,
        exact: true,
      },
      {
        path: RUTAS.empleados,
        component: Empleados,
        exact: true,
      },
      {
        path: RUTAS.sede,
        component: Sede,
        exact: true,
      },
      {
        path: RUTAS.usuarios,
        component: Usuarios,
        exact: true,
      },
      {
        path: RUTAS.lista,
        component: ListaAsistencia,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: RUTAS.login,
    component: BasicLayout,
    exact: false,
    routes: [
      {
        path: RUTAS.login,
        component: Login,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
