import React, { useEffect } from "react";
import { Form, Input, Button, TimePicker, notification } from "antd";
import { actualizarSede, listarSede } from "../../../api/sedes";
import moment from "moment";
import "./EditSedeModal.scss";

export default function EditSedeModal(props) {
  const { setIsVisibleModal, setReloadSede } = props;
  const [form] = Form.useForm();
  const format = "HH:mm";

  useEffect(() => {
    async function fetchData() {
      const response = await listarSede(props.idSede);
      form.setFieldsValue({
        nombre: response.data[0].nombre,
        direccion: response.data[0].direccion,
        horaEntrada: moment(response.data[0].horaEntrada, "HH:mm"),
        horaSalida: moment(response.data[0].horaSalida, "HH:mm"),
      });
    }
    fetchData();
  }, [props.idSede]);

  const onFinish = async (values) => {
    values.horaEntrada = moment(values.horaEntrada).format("HH:mm");
    values.horaSalida = moment(values.horaSalida).format("HH:mm");

    let response = await actualizarSede(props.idSede, values);

    setReloadSede(true);

    if (response.code === 200) {
      notification["success"]({
        message: "Éxito",
        description: response.message,
      });
      setIsVisibleModal(false);
    } else if (response.code === 400) {
      notification["error"]({
        message: "Error",
        description: response.message,
      });
      setIsVisibleModal(true);
    } else {
      notification["warning"]({
        message: "Error",
        description: response.message,
      });
      setIsVisibleModal(true);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} onFinish={onFinish} form={form}>
      <Form.Item
        label="Sede"
        name="nombre"
        rules={[
          {
            required: true,
            message: "Porfavor ingresa el nombre de la sede.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Dirección"
        name="direccion"
        rules={[{ required: true, message: "Porfavor ingresa la dirección." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Hora de entrada"
        name="horaEntrada"
        rules={[
          { required: true, message: "Porfavor ingresa la hora de entrada" },
        ]}
      >
        <TimePicker format={format} />
      </Form.Item>

      <Form.Item
        label="Hora de salida"
        name="horaSalida"
        rules={[
          { required: true, message: "Porfavor ingresa la hora de salida" },
        ]}
      >
        <TimePicker format={format} />
      </Form.Item>

      <Form.Item className="site-page-button">
        <div className="site-page-button">
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
